* {
  box-sizing: border-box;
}

body {
  background-image: url('./assets/images/mult-colored-hero.jpg');
  background-position: center;
  background-size: cover;
  color: rgb(27, 27, 27);
  font-family: 'Roboto', sans-serif;
}

.main-window {
  min-height: 100vh;
  position: relative;
  padding-bottom: 94px;
}

header {
  background-image: inherit;
  background-attachment: fixed;
  background-size: cover;
  height: fit-content;
  padding: 15px;
  width: 100%;
}

header h1 {
  font-weight: 700;
  background: inherit;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow:
    0 2px 1px 0 rgba(255, 255, 255, 0.4) inset,
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
}

header h1::before {
  background: inherit;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 200px rgba(255, 255, 255, .5),
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
  filter: blur(15px);
  z-index: -1;
}

main {
  background: inherit;
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 90%;
  border-radius: 10px;
  box-shadow:
    0 2px 1px 0 rgba(255, 255, 255, 0.4) inset,
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  main {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(5px);
    background-color: rgba(189, 189, 189, 0.6);
  }
}

p {
  font-size: 24px;
  font-weight: 400;
}

.content-wrap {
  padding-bottom: 80px;
}

.card-style {
  border-radius: 15px;
}

.btn-custom,
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active {
  border: none !important;
}

footer {
  height: 94px;
  background-image: inherit;
  background-position: bottom;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.nav-item {
  text-align: center;
  transition: color .5s;
  min-width: 100.250px;
  color: #111111;
  font-weight: 700;
  font-size: large;
  background: inherit;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  box-shadow:
    0 2px 1px 0 rgba(255, 255, 255, 0.4) inset,
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
}

.nav-item::before {
  transition: box-shadow .5s;
  background: inherit;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 180px rgba(255, 255, 255, .5),
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
  filter: blur(4px);
  z-index: -1;

}

#contact-form {
  max-width: 800px !important;
}

.nav-item:hover {
  color: #EEE
}

.nav-item:hover::before {
  box-shadow: inset 0 0 120px rgba(32, 32, 32, 0.9);
}

.nav-item-active {
  color: #EEE;
  box-shadow: inset 0 0 120px rgba(32, 32, 32, 0.2);
}

.nav-item-active::before {
  color: #EEE;
  box-shadow: inset 0 0 120px rgba(32, 32, 32, 0.9);
}

.awesome-icon {
  text-align: center;
  transition: color .5s;
  color: #111111;
  font-size: 48px;
  background: inherit;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  box-shadow:
    0 2px 1px 0 rgba(255, 255, 255, 0.4) inset,
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
}

.awesome-icon::before {
   transition: box-shadow .5s;
  background: inherit;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 200px rgba(255, 255, 255, .5),
    0 6px 15px 2px rgba(0, 0, 0, 0.5);
  filter: blur(4px);
  z-index: -1;
}

.awesome-icon:hover {
  color: #EEE;
}

.awesome-icon:hover::before {
  box-shadow: inset 0 0 400px rgba(32, 32, 32, 0.952);
}

.github-icon {
  padding: 0 13px;
}

.linkedin-icon {
  padding: 0 15px;
}

.stack-icon {
  padding: 0 18px;
}

.selfie {
  border-radius: 50%;
  display: block;
  margin: 25px auto;
  max-width: 20%;
  min-width: 200px;
  box-shadow:
    0 2px 1px 0 rgba(255, 255, 255, 0.4) inset,
    0 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.about-text {
  background-color: rgba(255, 255, 255, 0.131);
}

.grow {
  transition: all .2s ease-in-out;
}

.grow:hover {
  transform: scale(1.02);
}

.btn-close {
  margin-left: 0px !important;
}

@media screen and (min-width: 575px) {
  main {
    min-width: 80%;
  }

  body {
    background-image: url('./assets/images/john-fowler-5bv-_tIwHFk-unsplash.jpg');
  }
}

@media screen and (min-width: 767px) {
  main {
    min-width: 60%;
  }
}

@media screen and (min-width: 991px) {
  main {
    min-width: 50%;
  }
}